<template>
  <RaiSetup :next-loading="submitting" v-bind="$attrs" v-on="listeners">
    <template #extension>
      <slot name="extension"></slot>
    </template>
    <VxForm ref="form" v-slot="formProps" @submit="submit">
      <slot v-bind="formProps"></slot>
    </VxForm>
  </RaiSetup>
</template>

<script>
import { RaiSetup, VxForm } from "@/core-ui";

export default {
  name: "SetupForm",
  components: {
    RaiSetup,
    VxForm,
  },
  data: () => ({
    submitting: false,
  }),
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        next: this.onNext,
      };
    },
  },
  methods: {
    onNext() {
      this.$refs.form.submit();
    },
    submit(form) {
      this.submitting = true;

      const resolve = () => {
        this.submitting = false;
        this.$emit("next");
        form.resolve();
      };

      if (!this.$listeners["submit"]) resolve();
      this.$emit("submit", { ...form, resolve });
    },
  },
};
</script>
