export const AutomationStatus = {
  ACTIVE: "active",
  CONNECTED: "connected",
  NOT_CONNECTED: "not-connected",
  ERROR: "error",
};

export const AutomationType = {
  WHEN_I_WORK: "when-i-work",
  HOMEBASE: "homebase",
  QUICKBOOKS: "quickbooks",
  NIGHTLY_REPORTS: "nightly-reports",
  FIVESTARS: "fivestars",
  CONSTANT_CONTACT: "constant-contact",
};

export const TeamMemberRole = {
  OWNER: "owner",
  DISTRICT_MANAGER: "district",
  MANAGER: "manager",
};
